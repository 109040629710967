<template>
	<div class="attribute-item">
		<label v-text="itemData.title_ru"></label>
		<el-select
			v-model="option_id"
			multiple filterable
			:placeholder="`выберите ${itemData.title_ru}`"
			:disabed="!itemData.options.length"
		>
			<el-option
				v-for="item in itemData.options"
				:key="'attr_option_id-' + item.id"
				:label="item.title_ru"
				:value="item.id"
			/>
		</el-select>
	</div>
</template>

<script>
import { findItemBy } from '@/helpers';

export default {
	props: {
		// itemIndex: Number,
		// clearable: Boolean,
		// query: String,
		itemData: {
			type: Object,
			required: true
		},

		optionsIds: {
			type: Array,
			default: () => []
		}
	},

	data() {
		return {
			// new: false,
			option_id: []
		};
	},

	computed: {},

	created() {
		const { optionsIds, itemData } = this;
		if (optionsIds.length && itemData.options.length) {
			for (const option_id of optionsIds) {
				const item = findItemBy('id', option_id, itemData.options);
				if (item) {
					this.option_id.push(option_id);
				}
			}
		}
		// this.setupPage(this.itemData);
	},

	methods: {
		validateItemForm() {},

		removeItem() {
			this.$emit('onRemove', this.itemId);
		}
	}
};
</script>
